
ol.level1 {
    counter-reset: item;
    margin-left: 0;
    padding-left: 0;
}

li.level1 {
    display: block;
    margin-bottom: .5em;
    margin-left: 2em;
    font-size: x-large;
    counter-increment: item;
    @apply font-bold sm:text-xs lg:text-2xl;
}


li.level1::before {
    display: inline-block;
    content: " §" counter(item) ":";
    width: 2em;
    margin-left: -2em;
    padding-bottom: 10px;
}

ol.level2 {
    counter-reset: item2;
    margin-left: 10px;
    padding-left: 10px;
    list-style: decimal;
}

li.level2 {
    display: block;
    /*margin-bottom: .5em;*/
    margin-left: 0em;
    font-size: large;
    list-style: decimal;
    counter-increment: item2;
    line-height: 1.5em;
    margin-bottom: 1em;
    @apply font-normal
}


li.level2::before {
    display: inline-block;
    content: "(" counter(item2) ")";
    width: 2em;
    margin-left: -2em;
}

ol.level3 {
    counter-reset: item;
    margin-left: 20px;
    padding-left: 20px;
    list-style: lower-alpha;
}

li.level3 {
    /*margin-bottom: .5em;*/
    margin-left: 2em;
    list-style: lower-alpha;
}


li.level3::before {
    width: 2em;
    margin-left: -2em;
}

li.level4::before {
    display: inline-block;
    width: 2em;
    margin-left: -2em;
}

ol.level4 {
    margin-left: 25px;
    padding-left: 25px;
    list-style: lower-alpha;
}

li.level4 {
    margin-left: 2em;
    list-style: disc;
}


li.level4::before {
    width: 2em;
    margin-left: -2em;
}

.tooltip {
    position: relative;
    display: inline-block;
    left: -2px;
    color: #9ca3af;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: small !important;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}