@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  h1 {
    @apply text-2xl;
    padding-bottom: 5px;
  }
  h2 {
    @apply text-xl;
    padding-bottom: 3px;
  }
}

/* Small (xsm) */
@media (min-width: 300px) {
  div.timeline-border {
    left: 18%;
  }
}
/* Small (sm) */
@media (min-width: 640px) {
  div.timeline-border {
    left: 50%;
  }
}

/* Medium (md) */
@media (min-width: 768px) {
  div.timeline-border {
    left: 50%;
  }
}

/* Large (lg) */
@media (min-width: 1024px) {
  div.timeline-border {
    left: 50%;
  }
}

/* Extra Large (xl) */
@media (min-width: 1280px) {
  div.timeline-border {
    left: 50%;
  }
}

div.bgimg {
  background-image: url("./images/bg_beer.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  overflow: hidden;
}

#navbar > a.active {
  color: #ffff00;
  @apply bg-gray-700;
}

div.statuten > p {
  padding-bottom: 5px;
}

.bg-spreng {
  --tw-bg-opacity: 1;
  background-color: rgba(255,255,0,var(--tw-bg-opacity));
}

[data-amplify-authenticator][data-variation=modal] {
  background-color: rgb(31 41 55) !important;
}